import React from 'react'
import Carousel from './Carousel';

export default ({ discipline }) => {
  if(!discipline) return null;

  const { data } = discipline;

  return (
    <div class="pure-u-xl-1-3 pure-u-md-1-2 pure-u-1-1">
      <Carousel title={data.name.text} images={data.images.map(i => i.image)} />
    </div>
  );
}
