import React from 'react'
import { graphql } from 'gatsby';
import Layout from '../components/layouts'
import Discipline from '../components/Discipline';

// Query for the Blog Home content in Prismic
export const query = graphql`
{
  allPrismicBlogHome{
    edges{
      node{
        id
        type
        data {
          headline {
            html
            text
            raw
          }
          description {
            html
            text
            raw
          }
          image {
            alt
            copyright
            url
          }
          disciplines {
            discipline {
              __typename
              document {
                ... on PrismicDiscipline {
                  data {
                    name {
                      html
                      text
                      raw
                    }
                    images {
                      image {
                        alt
                        copyright
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`

// Using the queried Blog Home document data, we render the top section
const BlogHomeHead = ({ home }) => {  
  const disciplines = home.data.disciplines.map(({discipline}) => <Discipline discipline={discipline.document} />);

  return (
    <div className="blogHomeHead" data-wio-id={ home.id }>
      <h1>{ home.data.description.text }</h1>
      <div class="pure-g">
        {disciplines}
      </div>
    </div>
  );
};

export default ({ data }) => {
  // Define the Blog Home & Blog Post content returned from Prismic
  const doc = data.allPrismicBlogHome.edges.slice(0,1).pop();

  if(!doc) return null;

  return(
    <Layout>
      <BlogHomeHead home={ doc.node } />
    </Layout>
  )
}
